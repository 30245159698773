.services_outside_container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    max-height: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.hero_section_services {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../Assets/homebgimage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    gap: 16px;
    padding: 24px;
}

.hero_overlay_panel {
    background: rgba(0, 34, 128, 0.8);
    padding: 24px;
    width: 400px;
    display: flex;
    flex-direction: column;
}

.hero_overlay_panel p {
    color: #fff;
    padding: 8px;
}

.herder_text {
    color: white;

}

.button_watch_here {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 12px;
    cursor: pointer;
    margin-top: 24px;
    color: white;
}

.button_watch_here:hover {
    background-color: skyblue;
    border-radius: 16px;
    padding: 12px;
    color: white;
    cursor: pointer;
}

.services_container_style {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cards_container_style {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 24px;
}

.image_wrapper {
    width: 100%;
    max-height: 260px;
    height: 100%;
}

.image_style {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.service_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 16px;
}

.service_card_content {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.services_input_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    max-width: 500px;
}

.services_input_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid blue;
    border-radius: 8px;
    padding: 8px;
    width: fit-content;
}

.services_input_text {
    outline: none;
    border: none;
    padding: 0px;
}

.services_container_style h1 {
    font-size: 48px;
}

@media only screen and (max-width: 768px) {
    .hero_section_services {
        padding: 48px;
    }

    .cards_container_style {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
        padding: 24px;
    }
}

@media only screen and (max-width: 425px) {
    .hero_section_services {
        padding: 24px;
    }
}