.outside_container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    max-height: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* align-items: center; */
    /* align-items: start; */
    justify-content: start;
}

.main_container {
    display: grid;
    grid-template-columns: 25% 75%;

}

.solutions_container {
    background-color: whitesmoke;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-family: sans-serif;
    gap: 12px;
}

.solutions_container_head {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;
}

.solutions_container_head h1 {
    text-align: start;
    justify-content: center;

}

.solutions_container_head>h3 {
    padding: 0 0 10px;
    color: black;
}


.Solutions_header h3 {
    text-decoration: underline;

}

/* .Solutions_placeholder {
    text-align: center;
    align-items: center;
} */

.solutions_input_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    max-width: 500px;
}

.input_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid blue;
    border-radius: 8px;
    padding: 8px;
    width: fit-content;
}

.input_text {
    outline: none;
    border: none;
    padding: 0px;
}

@media only screen and (max-width: 768px) {
    .main_container {
        grid-template-columns: auto auto;
    }

    .solutions_container {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

@media only screen and (max-width: 425px) {
    .main_container {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}