.health_container_main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}


.health_container {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../Assets/Hospitalhero_img.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 24px;
}

.health_container h1 {
    font-size: 48px;
}

.health_container h1:hover {
    color: chocolate;
}

.healthmanagement_paragraph {
    max-width: 800px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}