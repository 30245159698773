.contact_us_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.contact_hero_container {
    width: 100%;
    height: 80vh;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgb(173, 213, 214) 0%, rgb(154, 154, 231) 35%, rgba(0, 212, 255, 1) 100%);
}

.contact_hero_container h1 {
    font-size: 48px;
}

.contact_hero_container p {
    font-size: 28px;
}

.contact_us_grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding: 24px;
}

.input_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    max-width: 650px;
}

.contact_input_text {
    height: 44px;
    width: 100%;
    border-radius: 12px;
    outline: none;
    border: 1px solid #CECECE;
    padding: 8px;
}

.contact_textarea {
    min-height: 150px;
    padding: 8px;
    width: 100%;
    border-radius: 12px;
    outline: none;
    border: 1px solid #CECECE;
    max-width: 600px;
}

.contact_button {
    width: 140px;
    height: 45px;
    border-radius: 12px;
    border: 1px solid #BCBCBC;
    cursor: pointer;
    background-color: #000;
    color: #ffffff;
}

.contact_us_social_container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.contact_us_social_container h1 {
    font-size: 50px;
}

.contact_us_social_container h2 {
    font-size: 30px;
}



.icons_social {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.office_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 24px;
    background-color: aqua;
}

.our_office_section {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}



@media only screen and (max-width: 768px) {

    .contact_us_grid_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        padding: 24px;
        gap: 24px;
    }

    .input_container {

        padding: 0px;
    }


    .contact_us_social_container {
        padding: 0px;

    }

    .office_container {

        grid-template-columns: repeat(1, 1fr);

    }

    .our_office_section {
        padding: 0px;

    }
}