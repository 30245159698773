.home_main_container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.home_hero_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    background-image: url("../Assets/heroimage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home_hero_container h1 {
    color: #fff;
    font-size: 60px;
    font-family: sans-serif;
    cursor: pointer;
}

.home_hero_container h1:hover {
    color: rgb(25, 223, 213);
}

.home_hero_container h2 {
    color: #fff;
    font-size: 40px;
    font-family: sans-serif;
    cursor: pointer;
}

.home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}


.home_header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.home_header h1 {
    color: skyblue;
    font-size: 40px;
    text-align: center;
    font-family: sans-serif;
}

.home_header p {
    line-height: 30px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: sans-serif;
}

.home_header h4 {
    color: skyblue;
    font-size: 30px;

}

.home_header button {
    background-color: blue;
    color: #fff;
    max-width: 165px;
    /* max-height: 100px; */
    width: 130px;
    height: 46px;
    font-size: 18px;
    border-radius: 10px;
    border: none;

}

.home_header button:hover {
    background-color: #fff;
    color: black;
    border: 1px solid #000;
    font-weight: bold;
}

.homepage_buttons button {
    margin: 70px;
}

.home_image_wrapper {
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.home_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scroll_container {
    overflow: scroll;
    width: 100%;
    scroll-behavior: smooth;
}

.home_body {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    gap: 24px;
    font-family: sans-serif;
    width: 1440px;
    padding: 24px;
    margin: auto;

}

.card_style {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.home_body_text {
    width: 350px;
}

.home_body_text h3 {
    font-size: 23px;
}

.buttons_container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.masa_icon img {
    height: 400px;

}

.masaclient_benefits h1 {
    font-size: 50px;
    margin: 5px;
    color: skyblue;

}

.masaclient_benefits p {
    font-size: 20px;
    line-height: 30px;
}



.home_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: navy;
    color: whitesmoke;
    text-align: center;
    align-items: center;
    padding: 18px;
}

.home_solutions_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: #0b3155;
}

.home_solutions_container h2,
h3 {
    color: #fff;
    font-family: sans-serif;
    padding-left: 5%;
}

.home_delivery_solutions h2,
h3 {
    color: #fff;
}

.home_body_info_para {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.home_body_info_para h1 {
    padding-bottom: 26px;
    font-size: 48px;
    color: #063e5f;

}

.home_body_info_para p {
    text-align: center;
    font-size: 18px;
}



.home_body_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding: 0 10%;
}

.home_body_card {
    padding: 24px;

}


.home_body_card p {
    padding-top: 18px;

}

.grid_box:hover {
    background-color: blue;
    color: whitesmoke;

}

.grid_box_container {
    display: grid;
    width: 95%;
    padding-left: 5%;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

}



.grid_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 24px;
    padding: 32px;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    min-height: 120px;
    border-radius: 6px;
    height: 100%;
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6);
}


.icon {
    position: absolute;
    color: blue;
    right: 5px;
    bottom: 5px;
    transition: 0.5s ease-in-out;
    transform: rotate(45deg);
}



.grid_box:hover .icon {
    transition: 0.5s ease-in-out;
    transform: rotate(-0deg);
    color: white;
}

.home_stories_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
}

.home_stories_container h1 {
    color: #063e5f;
    text-align: center;
    padding-bottom: 18px;
    font-size: 48px;
}


.grid_stories_1 {
    display: grid;
    grid-template-columns: 39% 59%;
    justify-content: space-between;
    gap: 16px;
}

.grid_stories_2 {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
    gap: 16px;
}


.one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-image: url(../Assets/successimg4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    min-height: 250px;
    height: 100%;
}

.two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-image: url(../Assets/cybersecurity_image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    min-height: 250px;
    height: 100%;
}

.three {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-image: url(../Assets/cloud_computing_image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    min-height: 250px;
    height: 100%;
}

.four {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-image: url(../Assets/masatvimg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    min-height: 250px;
    height: 100%;
}

.five {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-image: url(../Assets/testingimg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    min-height: 250px;
    height: 100%;
}

.one>p {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
}

.two>p {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
}

.three>p {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
}

.four>p {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
}

.five>p {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
}

@keyframes lights {
    0% {
        color: hsl(230, 40%, 80%);
        text-shadow:
            0 0 1em hsla(320, 100%, 50%, 0.2),
            0 0 0.125em hsla(320, 100%, 60%, 0.3),
            -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
            1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }

    aa 30% {
        color: hsl(230, 80%, 90%);
        text-shadow:
            0 0 1em hsla(320, 100%, 50%, 0.5),
            0 0 0.125em hsla(320, 100%, 60%, 0.5),
            -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
            0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }

    40% {
        color: hsl(230, 100%, 95%);
        text-shadow:
            0 0 1em hsla(320, 100%, 50%, 0.5),
            0 0 0.125em hsla(320, 100%, 90%, 0.5),
            -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
            0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
    }

    70% {
        color: hsl(230, 80%, 90%);
        text-shadow:
            0 0 1em hsla(320, 100%, 50%, 0.5),
            0 0 0.125em hsla(320, 100%, 60%, 0.5),
            0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
            -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }

    100% {
        color: hsl(230, 40%, 80%);
        text-shadow:
            0 0 1em hsla(320, 100%, 50%, 0.2),
            0 0 0.125em hsla(320, 100%, 60%, 0.3),
            1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
            -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }

}



.header_text_home {
    font-size: 3.5rem;
    font-weight: 300;
    animation: lights 5s 750ms linear infinite;
}

@media only screen and (max-width: 1075px) {
    .home_hero_container h1 {
        color: #fff;
        font-size: 50px;
        font-family: sans-serif;
        align-items: center;
        justify-content: center;
    }


}

@media only screen and (max-width: 971px) {
    .home_hero_container h1 {
        color: #fff;
        font-size: 50px;
        font-family: sans-serif;
        align-items: center;
        justify-content: center;
    }

    .home_body_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }


}

@media only screen and (max-width: 958px) {
    .grid_box_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }



}

@media only screen and (max-width: 900px) {
    .home_image_wrapper {
        max-width: 100%;
        width: 100%;
        max-height: 250px;
        height: 100%;
    }

    .home_hero_container h1 {
        color: #fff;
        font-size: 60px;
        font-family: sans-serif;
        align-items: center;
        justify-content: center;


    }

    .home_body_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }



    .buttons_container {
        display: none;
    }

    .home_body {
        overflow: visible;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .card_style {
        width: 200px;
    }
}

@media only screen and (max-width: 768px) {
    .home_container {
        display: flex;
        flex-direction: column;
        gap: 8px;

    }

    .home_image_wrapper {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        height: 100%;
    }

    .home_footer {
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;
    }

    .card_style {
        width: 100%;
    }

    .grid_box_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .grid_stories_1 {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .grid_stories_2 {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .grid_box:hover {
        background-color: blue;
        color: whitesmoke;

    }

}

@media only screen and (max-width: 425px) {

    .home_header h4 {
        color: skyblue;
        font-size: 18px;

    }

    .grid_box_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
}