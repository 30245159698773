.product_container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    height: 100%;
}

.products_hero_section {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: right;
    background-image: url('../Assets/products_containerimg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 24px;
}

.product_hero_container {
    max-width: 500px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}

.product_hero_container h1 {
    font-size: 38px;
    padding-bottom: 20px;
}

.product_hero_container p {
    font-size: 18px;

}

.product_grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 16px;
    padding: 24px;
}

.grid_content_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.capsule_button {
    background-color: lightblue;
    padding: 12px;
    border-radius: 24px;
    width: fit-content;
}



.visit_button {
    background-color: lightblue;
    padding: 12px;
    border-radius: 24px;
    width: 100px;
    height: 40px;
    /* border: 1px solid black; */
    border: none;
    font-weight: bold;

}

.product_image_wrapper {
    width: 100%;
    height: 100%;

}

.product_image_style {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;

}

.Products_description_about {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: black;
    gap: 24px;
    flex-wrap: wrap;
    padding: 24px;

}

.Products_card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    padding: 24px;
    width: calc(33.3333% - 24px);
    background: #FFF;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 10px;
    border: 1px solid #e3e3e3;

}

.Products_card:last-child {
    border-right: none;
}

/* .Products_card:hover {
    transform: translatey(-10px);
    border-radius: 30px;

} */

.Products_card p {
    padding: 0px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
}

.Products_card h2 {
    font-size: 24px;
}

@media screen and (max-width:1024px) {
    .Products_description_about {
        flex-wrap: wrap;
    }

    .Products_card:nth-last-child(3) {
        border-right: none;
    }
}

@media screen and (max-width:948px) {
    .Products_card:nth-last-child(3) {
        border-right: 2px solid blue;
    }

    .Products_card:nth-last-child(2) {
        border: none;
    }

    .Products_card:nth-last-child(4) {
        border: none;
    }
}

@media screen and (max-width:768px) {

    .Products_card {
        max-width: 100%;
        width: 100%;
        border: none;
    }

    .Products_card:nth-last-child(3) {
        border: none;
    }

    .product_grid_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        gap: 16px;
        padding: 24px;
    }
}

@media screen and (max-width:450px) {}