* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    background-color: rgb(0, 34, 128);
    color: whitesmoke;
    padding: 8px 24px;
}

.navbar li:hover {
    color: skyblue;

}

.logo {
    font-size: 30px;
}

.nav-links {
    display: flex;
    flex-direction: row;
    gap: 24px;
    list-style: none;
    align-items: center;
}



.home,
.Aboutus,
.products,
.services,
.solutions,
.dropButton,
.contactus {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: whitesmoke;
    font-size: 20px;
    cursor: pointer;
    padding: 20px 0 20px 0;


}



.contactus {
    border: 1px solid whitesmoke;
    border-radius: 5px;
    text-decoration: none;
    color: whitesmoke;
    font-size: 20px;
    cursor: pointer;
    padding: 6px 8px;
}

.mobile-menu-icon {
    display: none;
}

/* drop down */
.dropButton {
    position: relative;

}

.dropButton .fa {
    margin-left: 6px;
}

.dropdown-content {
    position: absolute;
    top: 60px;
    background-color: black;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    width: fit-content;
    /* max-height: 400px;
    overflow: scroll; */
}

.dropdown-content a {
    color: #fff;
    padding: 8px 14px;
    text-decoration: none;
    display: block;
    word-break: keep-all;
    white-space: nowrap;
}



@media screen and (max-width:780px) {
    .logo {
        display: flex;
        position: absolute;
        top: 15px;
        left: 25px;
    }

    .nav-links {
        display: none;
    }


    .nav-links-mobile {
        position: fixed;
        display: block;
        list-style: none;
        background-color: navy;
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 100%;
        z-index: 10;
        height: 90vh;
        overflow: scroll;
        padding: 24px;
    }

    .home,
    .products,
    .services,
    .solutions,
    .contactus,
    .Aboutus,
    .dropButton {
        color: whitesmoke;
        width: 100%;
        transition: all 0.5s ease;
        flex-direction: column;
        text-align: center;
    }






    .contactus {
        border: none;

    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        font-size: 30px;
        color: whitesmoke;
        background-color: navy;
        border: none;
        outline: none;
        top: 15px;
        right: 25px;
    }

    .dropdown-content {
        position: unset;
        background-color: navy;
        width: 100%;
        box-shadow: none;
        padding: 12px 16px;
        z-index: 1;
        /* max-height: 400px;
        overflow: scroll; */
    }
}