.about_main_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;


}

.about_position_container {
    position: relative;
    height: 100vh;
}

.about_hero_container {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: linear-gradient(90deg, rgb(179, 179, 185) 0%, rgb(61, 61, 61) 35%, rgb(137, 138, 137) 100%);
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.about_hero_container h1 {
    font-size: 50px;
    color: #fff;
}

.about_hero_container p {
    font-size: 22px;
    color: #fff;
}

.cards_abot_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 24px;
    position: absolute;
    bottom: 0px;
}

.card_about_page {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.grid_about_content_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.grid_about_content_container h2 {
    font-size: 40px;
}

.about_grid_containner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 24px;
}

.about_image_wrapper {
    width: 100%;
    height: 100%;

}

.about_image_style {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;

}

._about_icon_main_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
}

.icon_about_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.icon_about_container p {
    font-size: 24px;
}

.button_about_us {
    width: fit-content;
    border-radius: 12px;
    border: 1px solid #000;
    padding: 12px;
    background-color: #2B7CFA;
    color: #fff;
}

.client_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px;
}

.client_container p {
    justify-content: center;
    text-align: center;
    align-items: center;
}

.client_container h2 {
    font-size: 48px;
    font-family: sans-serif;
    color: #063e5f;
}

.client_feedback_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.experiance_grid_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 24px;
    align-items: center;
    justify-content: center;
    background-color: #E5EED8;

}

.projects_grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.headerText {
    color: blue;
    font-size: 50px;
    background: -webkit-linear-gradient(#bb538c, #6077c5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.projects_grid p {

    font-size: 42px;
    font-family: sans-serif;
}

.client_feedback_container p {
    font-size: 24px;
    font-family: sans-serif;
}

.client_feedback_container h4 {
    color: #2B7CFA;
}


@media only screen and (max-width: 768px) {
    .about_position_container {
        position: relative;
        height: 180vh;
    }

    .cards_abot_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        padding: 24px;
        position: absolute;
        top: 60vh;
    }

    .about_grid_containner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        padding: 24px;
    }

    .client_container {

        padding: 0px;
    }

    .experiance_grid_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 24px;
        align-items: center;
        justify-content: center;

    }
}